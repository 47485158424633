export default [
  {
    header: 'Hesap',


    action: 'read',
    resource: 'Auth',
  },
/*  {
    title: 'Hesap Bilgilerim',
    route: 'account',
    icon: 'HomeIcon',


    action: 'read',
    resource: 'Auth',
  },*/
  {
    title: 'Çıkış',
    route: 'logout',
    icon: 'HomeIcon',


    action: 'read',
    resource: 'Auth',
  },
]
