export default [
  {
    header: 'Anasayfa',


    action: 'read',
    resource: 'Auth',
  },
  {
    title: 'Anasayfa',
    route: 'admin-home',
    icon: 'HomeIcon',

    action: 'read',
    resource: 'admin',
  },
  {
    title: 'Anasayfa',
    route: 'vet-home',
    icon: 'HomeIcon',

    action: 'read',
    resource: 'vet',
  },
  {
    title: 'Anasayfa',
    route: 'groomer-home',
    icon: 'HomeIcon',

    action: 'read',
    resource: 'groomer',
  },
  {
    title: 'Anasayfa',
    route: 'petshop-home',
    icon: 'HomeIcon',

    action: 'read',
    resource: 'petshop',
  },
]
