export default [
  {
    header: 'İçerik Yönetimi',

    action: 'read',
    resource: 'admin_services',
  },


  {
    title: 'Hizmetler',
    route: 'admin_expertises',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'admin_services',
  },
  {
    title: 'Servisler',
    route: 'admin_services',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'admin_services',
  },
  {
    title: 'Kullanıcı Türleri',
    route: 'membership_types',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'membership_types',
  },


  {
    title: 'Ayarlar',
    route: 'admin_settings',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'settings',
  },


  {
    title: 'Pet Türleri',
    route: 'admin_pettypes',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'pettype',
  },
  {
    title: 'Pet Aşı Türleri',
    route: 'admin_pet_vac_types',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'pettype',
  },


  {
    title: 'Ürün Markaları',
    route: 'admin_product_brands',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'admin_product',
  },
  {
    title: 'Ürün Kategorileri',
    route: 'admin_product_category',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'admin_product',
  },
  {
    title: 'Ürün Birim Cinsleri',
    route: 'admin_product_unit_type',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'admin_product',
  },
  {
    title: 'SSS Kategoriler',
    route: 'faq_categories',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'faq',
  },
  {
    title: 'SSS Sorular',
    route: 'admin_faq_list_empty',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'faq',
  },
  {
    title: 'Soru-Öneriler',
    route: 'admin_question_suggestion',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'question_suggestion',
  },
  {
    title: 'Blog',
    route: 'admin_blog',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'blog',
  },
  {
    title: 'Kullanıcılar',
    route: 'users',
    icon: 'HomeIcon',

    action: 'manage',
    resource: 'users',
  },
]
