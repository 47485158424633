/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

import homePages from './app-nav/homepages'
import products from './app-nav/Product'
import company from './app-nav/company'

import admin from './app-nav/admin'

import account from './app-nav/account'

// Array of sections
export default [...homePages, ...admin, ...products, /*...company,*/ ...account]
