export default [
  {
    header: 'E-Ticaret',


    action: 'read',
    resource: 'products',
  },
  {
    title: 'Ürün Yönetimi',
    route: 'products',
    icon: 'HomeIcon',


    action: 'read',
    resource: 'products',
  },
  {
    title: 'Sipariş Yönetimi',
    route: 'orders',
    icon: 'HomeIcon',


    action: 'read',
    resource: 'orders',
  },
]
